<template>
  <f7-page id="home" name="home">
    <!-- Top Navbar -->
    <f7-navbar id="navbar">
      <f7-nav-left>
        <f7-link
          icon-ios="f7:menu"
          icon-aurora="f7:menu"
          icon-md="material:menu"
          panel-open="left"
        ></f7-link>
        <f7-link
          :icon-ios="selectedBaseMarker ? 'f7:delete' : 'f7:perm_identity'"
          :icon-aurora="selectedBaseMarker ? 'f7:delete' : 'f7:perm_identity'"
          :icon-md="
            selectedBaseMarker ? 'material:delete' : 'material:perm_identity'
          "
          @click="baseMarkerAddRemove"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title>TrackMapz</f7-nav-title>
      <f7-nav-right>
        <f7-link
          :icon-ios="routeMarkerStartShow || route ? 'f7:clear' : 'f7:home'"
          :icon-aurora="routeMarkerStartShow || route ? 'f7:clear' : 'f7:home'"
          :icon-md="
            routeMarkerStartShow || route ? 'material:clear' : 'material:home'
          "
          @click="routeMarkerStartStart"
        ></f7-link>

        <f7-link
          :class="routeMarkerStartShow && !route ? 'enabled' : 'disabled'"
          :icon-ios="selectedWaypointID ? 'f7:delete' : 'f7:add_location'"
          :icon-aurora="selectedWaypointID ? 'f7:delete' : 'f7:add_location'"
          :icon-md="
            selectedWaypointID ? 'material:delete' : 'material:add_location'
          "
          @click="routeMarkerAddRemoveWaypoint"
        ></f7-link>

        <f7-link
          :class="routeMarkerStartShow && !route ? 'enabled' : 'disabled'"
          icon-ios="f7:flag"
          icon-aurora="f7:flag"
          icon-md="material:flag"
          @click="routeMarkerEndStart"
        ></f7-link>
      </f7-nav-right>
    </f7-navbar>

    <!-- Page content
     <div id="mapid" style="height: 400px; width: 100%">-->

    <div id="mapid" style="width: 100%" :style="{ height: mapHeight + 'px' }">
      <l-map
        ref="myMap"
        @ready="mapReady()"
        v-if="true"
        :zoom="zoom"
        :center="center"
        :options="mapOptions"
        :style="{ height: mapHeight + 'px' }"
        @update:center="centerUpdate"
        @update:zoom="zoomUpdate"
      >
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>

        <!--  <v-geosearch ref="mySearch" :options="geosearchOptions"></v-geosearch>  -->
      </l-map>
    </div>
  </f7-page>
</template>
<script>
import "leaflet/dist/leaflet.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from "vue2-leaflet";

import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-routing-machine";

//import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
//import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

import "./../js/Leaflet.AccuratePosition.js"; // find the GPS position

export default {
  name: "Example",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
  },
  mounted() {
    this.mapHeightSet();
    this.map = this.$refs.myMap.mapObject;
  },
  created() {
    window.addEventListener("resize", this.mapResize);
  },

  data() {
    return {
      baseMarker: null, // current position marker

      routeMarkerStart: null, // marker, when no route is planned
      routeMarkerStartShow: false, // indicator that start marker is shown,
      //even when the marker is not show, when route was planned
      routeMarkerEnd: null, // marker, when no route is planned
      routeMarkerEndShow: false,
      waypoints: [], // all the waypoint markers, when no route is planned
      selectedWaypointID: null, // selected waypoint when clicked
      selectedBaseMarker: null, // selected basemarker when clicked
      routeStart: null, // (not used functionality) get latlng for start marker when the route is undone
      routeEnd: null, // (not used functionality)  to get latlng for end marker when the route is undone
      route: null, // route object
      zoom: 13,
      mapHeight: 300, // is used to define the right height for the map view after resizing
      map: null, // leaflet map
      center: latLng(47.41322, -1.219482),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 11.5,
      currentCenter: latLng(47.41322, -1.219482),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
      },
      posFunction: null, // callback function after a gps position is found
      // does not work from here. needs to be specified in function..
    };
  },
  methods: {
    mapResize() {
      // resize the map based on the new view dimensions
      this.mapHeightSet();
      if (this.map) {
        this.map.invalidateSize();
      }
    },
    mapHeightSet() {
      // set the new height of the map based on offsetheight
      if (document.getElementById("home")) {
        var pageHeight = document.getElementById("home").offsetHeight;
        var navHeight = document.getElementById("navbar").offsetHeight;
        var mapHeightNew = pageHeight - navHeight;

        if (mapHeightNew != this.mapHeight) {
          this.mapHeight = mapHeightNew;
        }
      }
    },

    mapClicked() {
      // when there is a click somewhere on the map unselect
      // waypoint or base marker
      if (this.selectedWaypointID) {
        this.selectedWaypointID = null;
      }
      if (this.selectedBaseMarker) {
        this.selectedBaseMarker = null;
      }
    },
    routeMarkerAddRemoveWaypoint() {
      // Add a waypoint or remove a selected waypoint
      // waypoint id is the number of the waypoint
      if (this.selectedWaypointID) {
        this.routeMarkerRemoveWaypoint();
      } else {
        this.routeMarkerAddWaypoint();
      }
    },

    baseMarkerAddRemove() {
      // Add a basemarker or remove the selected basemarker
      this.selectedWaypointID = null;
      if (!this.selectedBaseMarker) {
        this.findPosition(this.setBaseMarkerOn);
      } else {
        this.removeBaseMarker();
        this.selectedBaseMarker = null;
      }
    },
    routeMarkerStartStart() {
      // set the start marker manually on the map center
      // if the routemarker is not yet shown
      if (!this.routeMarkerStartShow) {
        this.setRouteMarkerStartOn(this.map.getCenter());
      } else {
        // Remove everything if the route marker is shown
        this.removeRouteMarkerStart();
        // remove all waypoints and end marker
        this.waypointsRemove();
        if (this.routeMarkerEndShow) {
          this.removeRouteMarkerEnd();
          this.routeMarkerEndShow = !this.routeMarkerEndShow;
        }
      }
      this.routeMarkerStartShow = !this.routeMarkerStartShow;
      // try to display the route map
      this.mapTryRoute();
    },
    routeMarkerEndStart() {
      // Set the end marker manually
      this.selectedWaypointID = null;
      if (!this.routeMarkerEndShow) {
        this.setRouteMarkerEndOn(this.map.getCenter());
      } else {
        this.removeRouteMarkerEnd();
      }
      this.routeMarkerEndShow = !this.routeMarkerEndShow;
      this.mapTryRoute();
    },
    waypointsRemove() {
      // remove all waypoints that are set manually
      for (var i = this.waypoints.length; i--; ) {
        var waypoint = this.waypoints[i];
        waypoint.remove();
      }
      this.waypoints = [];
    },
    waypointIconCreate(id) {
      // create the icon with the id
      var waypointIcon = L.divIcon({
        className: "number-icon",
        iconSize: [25, 41],
        iconAnchor: [10, 44],
        popupAnchor: [3, -40],
        html: id,
      });
      return waypointIcon;
    },
    routeMarkerRemoveWaypoint() {
      // remove the selected waypoint
      if (this.selectedWaypointID) {
        this.waypointRemove(this.selectedWaypointID);
        this.selectedWaypointID = null;
      }
    },
    waypointRemove(ID) {
      // remove the waypoint using the ID
      // create the a new list of waypoints, and rearrange the ids
      var newList = [];
      //console.log(this.waypoints.length.toString());
      var count = 1;
      for (var i = 0; i < this.waypoints.length; i++) {
        var waypoint = this.waypoints[i];
        var latLng = waypoint.getLatLng();
        var waypointID = waypoint.options.icon.options.html;
        //console.log(i.toString());
        // create new waypoints except the selected one which is deleted
        if (waypointID !== ID) {
          var waypointMarker = this.createWaypointMarker(count, latLng);
          newList.push(waypointMarker);
          count++;
        }
      }
      // remove all the current waypoints
      this.waypointsRemove();
      // fill the waypoints with the new list
      this.waypoints = newList;

      // alert(waypoint.options.icon.options.html);
    },
    createWaypointMarker(id, position) {
      // create a new waypoint marker with ID
      var waypointIcon = this.waypointIconCreate(id);
      var result = new L.marker(position, {
        icon: waypointIcon,
        draggable: true,
      });

      result.addTo(this.map);
      result.on("click", this.markerOnClick);
      return result;
    },

    routeMarkerAddWaypoint() {
      // create a new waypoint with the next ID
      var len = this.waypoints.length;
      var id = len + 1;

      var waypointMarker = this.createWaypointMarker(
        id.toString(),
        this.map.getCenter()
      );

      this.waypoints.push(waypointMarker);
    },

    markerOnClick(e) {
      // a waypoint marker is selected on the map
      for (var i = 0; i < this.waypoints.length; i++) {
        var waypoint = this.waypoints[i];
        var latLng = waypoint.getLatLng();
        // check if the latling of the waypoint is the same as the
        //latling of the selected waypoint
        if (e.latlng == latLng) {
          this.selectedWaypointID = waypoint.options.icon.options.html;
          // if the basemarker is selected, deselect it. only 1 object can be selected
          this.selectedBaseMarker = null;
        }
      }
    },
    baseMarkerOnClick(e) {
      // base marker is selected
      this.selectedBaseMarker = e;
      this.selectedWaypointID = null;
    },

    mapTryRoute() {
      // try to map a route
      // if the start and end marker is shown.
      if (this.routeMarkerStartShow && this.routeMarkerEndShow) {
        var start = this.routeMarkerStart.getLatLng();
        var end = this.routeMarkerEnd.getLatLng();
        // remove the start and end marker because
        // the markers are re-created by mapping the route
        // but keep the attributes that the markers are shown.
        this.removeRouteMarkerStart();
        this.removeRouteMarkerEnd();
        this.mapRoute(start, end);
      } else {
        // remove the route map
        if (this.route) {
          // if route is shown
          if (this.routeMarkerStartShow) {
            // if start marker should be shown create this
            this.setRouteMarkerStartOn(this.routeStart.getLatLng());
          }
          if (this.routeMarkerEndShow) {
            // if end marker should be shown create this
            this.setRouteMarkerEndOn(this.routeEnd.getLatLng());
          }
          // remove the route map control
          this.map.removeControl(this.route);
          this.route = null;
        }
      }
    },

    mapRoute(start, end) {
      // map the route
      let this_self = this;
      // make a list of all waypoint including start and end
      var waypoints = [start];
      for (var i = 0; i < this.waypoints.length; i++) {
        var waypoint = this.waypoints[i];
        waypoints.push(waypoint.getLatLng());
      }
      waypoints.push(end);

      // create the route map
      let apiKey =
        "pk.eyJ1Ijoiam9jaGVtY2IiLCJhIjoiY2tpdzN1aWszMzFoazMzcDM3d285bzE5eiJ9.EJKOwkZE4-BWVuBA9kYfBA";
      let options = { profile: "mapbox/walking" };
      this.route = L.Routing.control({
        router: new L.Routing.mapbox(apiKey, options),

        waypoints: waypoints,
        createMarker: function (i, wp, nWps) {
          // create the start marker
          if (i === 0) {
            return (this_self.routeStart = L.marker(wp.latLng, {
              draggable: true,
              icon: this_self.waypointIconCreate("H"),
            }));
          } else {
            // create the end marker
            if (i === nWps - 1) {
              return (this_self.routeEnd = L.marker(wp.latLng, {
                draggable: true,
                icon: this_self.waypointIconCreate("F"),
              }));
            } else {
              // create the waypoint markers between start and end
              var count = i;
              return L.marker(wp.latLng, {
                draggable: true,
                icon: this_self.waypointIconCreate(count.toString()),
              });
            }
          }
        },
      }).addTo(this.map);
      // hide the navigation steps popup
      this.route.hide();
      // on found route calculate the KM distance
      this.route.on("routesfound", function (e) {
        var routes = e.routes;
        var summary = routes[0].summary;
        // alert distance and time in km and minutes
        var km = Math.round((summary.totalDistance / 1000) * 10) / 10;
        this_self.routeEnd.bindTooltip("route: " + km + " km").openTooltip();
        //alert("Total distance is " + summary.totalDistance / 1000);
      });

      this.waypointsRemove();
      // remove all the manually placed waypoints
      this.waypoints = [];
    },

    mapReady() {
      // on map ready initialize the leaflet map
      // resize map based on current device
      this.map.invalidateSize();
      this.getLocation(this.mapToPosition);

      this.map.on("accuratepositionprogress", this.onAccuratePositionProgress);
      this.map.on("accuratepositionfound", this.onAccuratePositionFound);
      this.map.on("accuratepositionerror", this.onAccuratePositionError);
      this.map.on("click", this.mapClicked);
    },

    findPosition(aFunction) {
      // find the gps position  (until 10 secs)
      this.showPreloader();
      // ste the callback function
      this.posFunction = aFunction;

      this.map.findAccuratePosition({
        maxWait: 10000,
        desiredAccuracy: 20,
      });
    },

    mapToPosition(position) {
      this.center = position;
    },

    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    removeBaseMarker() {
      // remove the basemarker
      if (this.baseMarker) {
        this.baseMarker.remove();
      }
    },
    removeRouteMarkerStart() {
      // remove the start marker
      if (this.routeMarkerStart) {
        this.routeMarkerStart.remove();
      }
    },
    removeRouteMarkerEnd() {
      // remove the end marker
      if (this.routeMarkerEnd) {
        this.routeMarkerEnd.remove();
      }
    },
    showPreloader() {
      // show wait sign with id
      const this_self = this;
      this_self.$f7.preloader.show("blue");
    },
    hidePreloader() {
      // hide the preloader
      const this_self = this;
      this_self.$f7.preloader.hide();
    },
    onAccuratePositionError(e) {},

    onAccuratePositionProgress(e) {},

    onAccuratePositionFound(e) {
      // if the GPS or other position in found , run the callback function
      // with the found position
      this.posFunction(e.latlng);
      this.posFunction = null;
      this.hidePreloader();
    },

    setBaseMarkerOn(position) {
      // set the base marker on the position.
      // if the old one exists, remove it
      this.removeBaseMarker();
      this.baseMarker = new L.marker(position, {
        icon: L.divIcon({
          className: "circle-marker-icon",
          html: "",
          iconSize: L.point(20, 20),
        }),

        draggable: false,
      }).addTo(this.map);
      this.baseMarker.on("click", this.baseMarkerOnClick);
      this.mapToPosition(position);
    },
    setRouteMarkerStartOn(position) {
      // set the start marker on the position
      if (this.routeMarkerStart) {
        this.routeMarkerStart.remove();
      }
      this.routeMarkerStart = new L.marker(position, {
        icon: this.waypointIconCreate("H"),
        draggable: true,
      }).addTo(this.map);
    },
    setRouteMarkerEndOn(position) {
      // set the end marker on the position
      if (this.routeMarkerEnd) {
        this.routeMarkerEnd.remove();
      }
      this.routeMarkerEnd = new L.marker(position, {
        icon: this.waypointIconCreate("F"),
        draggable: true,
      }).addTo(this.map);
    },

    getLocation(func) {
      // get simple location. Not GPS so not accurate.
      // sometimes 100 meter wrong. GPS works much better
      var position;
      let this_self = this;
      this.map
        .locate({
          watch: true,
          enableHighAccuracy: true,
        }) /* This will return map so you can do chaining */
        .on("locationfound", function (e) {
          position = latLng(e.latitude, e.longitude);
          func(position);
          this_self.map.stopLocate();
        });
    },
    //  innerClick() {
    // there is a click somewher on the map but not in a marker
    //    var m_map = this.map;
    //   var icon = this.marker_icon;

    //   if (this.marker_base) {
    //     this.marker_base.remove();
    //   }
    //    var marker = this.marker_base;
    //    navigator.geolocation.getCurrentPosition(function (loc) {
    //     var m = latLng(loc.coords.latitude, loc.coords.longitude);
    //    marker = new L.marker(m, { icon: icon }).addTo(m_map);
    //});
    // },
  },
};
</script>
